<template>
  <div
    style="width: 480px; min-height: 580px; padding-bottom: 60px"
    class="relative bg-white shadow rounded-lg"
  >
    <div class="relative">
      <img src="@/assets/images/survey-header.png" alt="" />
      <a-icon type="close" class="absolute top-20 right-20" @click="cancel1" />
    </div>

    <div class="overflow-hidden w-full relative text-left">
      <transition-group
        name="custom-classes-transition"
        mode="out-in"
        :enter-active-class="`animate__animated ${
          animateFlag === 'next'
            ? 'animate__slideInRight'
            : 'animate__slideInLeft'
        }`"
        :leave-active-class="`animate__animated absolute ${
          animateFlag === 'next'
            ? 'animate__slideOutLeft'
            : 'animate__slideOutRight'
        }`"
      >
        <div
          v-for="item in question"
          :key="item.ID"
          class="p-20"
          v-show="index === item.ID && !complete"
        >
          <QuestionForm
            :model="model"
            :dataSource="item"
            @select="select"
          ></QuestionForm>
        </div>
        <div v-if="complete" class="w-full px-20 text-center" :key="123">
          <img
            src="@/assets/images/smile.svg"
            class="mx-auto pt-40 mb-20"
            alt=""
          />
          <div class="mb-10 text-111 text-xl font-bold">
            您已完成所有问卷，非常感谢！
          </div>
          <div class="text-sm text-999">
            <span v-if="role === 1"
              >（友情提示您还未关联企业，关联后您可以体验平台全部功能哦）</span
            >
            <span v-else-if="role === 3"
              >（友情提示您还未认证企业，认证后您可以体验平台全部功能哦）</span
            >
          </div>
        </div>
      </transition-group>
    </div>
    <a-space class="absolute bottom-20 left-1/2 transform -translate-x-1/2">
      <a-button
        v-if="!complete"
        @click="cancel"
        :type="cancelType"
        :disabled="cancelType === 'disabled'"
        >{{ cancelText }}</a-button
      >
      <a-button
        v-if="okType === 'disabled' && !complete"
        @click="submit"
        type="primary"
      >
        提交
      </a-button>
      <a-button
        v-if="okType !== 'disabled' && !complete"
        @click="ok"
        :type="okType"
        :disabled="okType === 'disabled'"
        >{{ okText }}</a-button
      >
      <a-button v-if="complete && role === 1" type="primary" @click="go1"
        >去关联</a-button
      >
      <a-button v-if="complete && role === 3" type="primary" @click="go2"
        >去认证</a-button
      >
      <a-button v-if="complete" type="primary" @click="goHome">知道了</a-button>
    </a-space>
  </div>
</template>

<script>
import QuestionForm from "@/components1/QuestionForm/index.vue";
import { getQuestion, submitQuestion, getMyQuestion } from "@/api1/common";
export default {
  components: { QuestionForm },
  data() {
    return {
      visible1: false,
      question: [],
      nextId: 0,
      current: {},
      model: {},
      path: [],
      first: 0,
      animateFlag: "next",
      complete: false,
    };
  },
  computed: {
    okText() {
      return "下一题";
    },
    cancelText() {
      return "上一题";
    },
    okType() {
      return this.nextId ? "primary" : "disabled";
    },
    cancelType() {
      return this.current.ID !== this.first ? "primary" : "disabled";
    },
    index() {
      return this.current.ID;
    },
    role() {
      return this.$store.state.role;
    },
    survey_id() {
      return this.$store.state.config.config.UserSurvey;
    },
  },
  created() {
    this.getQuestion();
    this.visible1 = true;
  },
  methods: {
    getQuestion() {
      getQuestion(this.survey_id).then(({ data }) => {
        this.question = data.Detail.Items || [];
        this.current = this.question[0];
        this.nextId = this.current.NextItem;
        this.first = this.current.ID;
      });
    },
    submit() {
      if (!this.model[this.current.ID]) {
        return this.$message.warn("请选择");
      }
      this.complete = true;
      const paths = [...this.path];
      paths.push(this.current);
      const temp = paths.map((data) => {
        const temp = { ...data };
        temp.Options = data.Options.filter((item) => {
          if (data.Type === 2) {
            return this.model[data.ID].includes(item.ID);
          } else {
            return item.ID === this.model[data.ID];
          }
        });
        return temp;
      });
      submitQuestion(this.survey_id, { items: temp })
        .then(() => {
          this.model = {};
          this.complete = true;
        })
        .catch(() => {
          this.$message.error("提交问卷失败");
        });
    },
    ok() {
      if (!this.model[this.current.ID]) {
        return this.$message.warn("请选择");
      }
      if (
        this.current.Limit &&
        this.model[this.current.ID].length > this.current.Limit
      ) {
        return this.$message.warn(`最多选择${this.current.Limit}个`);
      }
      this.animateFlag = "next";
      this.path.push({ ...this.current });
      this.current = this.question.find((item) => item.ID === this.nextId);
      this.nextId = this.current.NextItem;
    },
    cancel() {
      this.animateFlag = "";
      this.current = this.path.pop();
      this.nextId = this.current.NextItem;
      if (
        this.model[this.current.ID] &&
        !Array.isArray(this.model[this.current.ID])
      ) {
        let nextId = this.current.Options.find(
          (item) => item.ID === this.model[this.current.ID]
        ).NextItem;
        if (nextId) this.nextId = nextId;
      }
    },
    select(e) {
      if (e) {
        this.$set(this.model, this.current.ID, e);
        let nextId;
        if (!Array.isArray(e)) {
          nextId = this.current.Options.find((item) => item.ID === e).NextItem;
          if (nextId) this.nextId = nextId;
        }
      }
    },
    getMyQuestion() {
      getMyQuestion()
        .then(() => {})
        .catch(() => {
          if (this.questionFlag) {
            this.visible = true;
          }
        });
    },
    cancel1() {
      this.$router.push("/");
    },
    go1() {
      this.$router.push("/account/user-setting");
    },
    go2() {
      this.$router.push("/company/recoginze");
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>